import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useClickOutside from '../../hooks/useClickOutside';
import './WorkspaceDropdown.scss';
import { t } from '../../i18n/i18n';
import ArrowDropdown from '../../assets/dashboard/ArrowDropdown';
import StarComplete from '../../assets/tests/StarComplete';
import StarEmpty from '../../assets/tests/StarEmpty';
import ModalCustom from '../ModalCustom/ModalCustom';
import FormInputSearch from '../FormInputSearch/FormInputSearch';
import {
  setCurrentWorkspace,
  setWorkspaceFavorite,
  setWorkspacesList,
  setUser,
  setCurrentRole,
} from '../../redux/actions/authActions';
import { CONFIGURATION, HOME, SCREENING_SEARCHES, CREATED_WORKSPACE } from '../../constants/routes';
import { setFavoriteWorkspace, createWorkspaceAsync } from '../../API/services/screeningService';
import { determineIconType } from '../../utils/determineIconWorkspace/determineIconWorkspace';
import { setLocalStorageWorkspace } from '../../utils/workspaceUtils';
import { addTruncate } from '../../utils/addTruncate';
import { getMe } from '../../API/services/authService';
import { PartnerWorkspaceRole } from '../../constants/enums';

const WorkspaceDropdown = ({ className, collapsed }) => {
  const { user, workspacesList, currentWorkspace } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [workspaceInfo, setWorkspaceInfo] = useState({
    placeholder: '',
    color: '',
    icon: undefined,
    iconId: undefined,
  });
  const [workspaceName, setWorkspaceName] = useState('');
  const [open, setOpen] = useState(false);

  const [searchWorkspace, setSearchWorkspace] = useState('');
  const [showModalAddWorkspace, setShowModalAddWorkspace] = useState(false);
  const [createWorkspaceLoader, setCreateWorkspaceLoader] = useState(false);
  const [worskpacesListFilter, setWorkspacesListFilter] = useState([]);
  const ref = useRef(null);
  const history = useHistory();
  useClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    if (workspacesList?.length) {
      const workspace =
        workspacesList.find((workspace) => workspace.id === currentWorkspace) ?? workspacesList[0];
      setWorkspaceInfo({
        placeholder: workspace?.name ?? '',
        color: workspace?.color ?? '',
        icon: workspace?.icon ?? '',
        iconId: workspace?.iconId ?? undefined,
      });
      setLocalStorageWorkspace(workspace);
      setWorkspacesListFilter(workspacesList);
    }
  }, [currentWorkspace, workspacesList]);

  const setWorkspace = (workspaceId) => {
    setOpen(false);
    dispatch(setCurrentWorkspace(workspaceId));
    dispatch(
      setCurrentRole(
        user.workspaces.filter((workspace) => workspace.workspaceId === workspaceId)[0]?.role
      )
    );
    if (history.location.pathname !== SCREENING_SEARCHES && history.location.pathname !== HOME) {
      history.push(HOME);
    }
  };

  useEffect(() => {
    const workspaceData = user.workspaces.map((w) => ({
      id: w.workspaceId,
      favorite: w.favorite,
      color: w.workspace.color,
      name: w.workspace.name,
      icon: w.workspace.icon,
      iconId: w.workspace.iconId,
      createdAt: w.workspace.createdAt,
      updatedAt: w.workspace.updatedAt,
      companyId: w.workspace.companyId,
      companyName: w.companyName,
      companyLogo: w.companyLogo,
      companyPrimaryColor: w.companyPrimaryColor,
      role: w.role,
    }));
    dispatch(setWorkspacesList(workspaceData));
  }, []);

  useEffect(() => {
    if (searchWorkspace) {
      setWorkspacesListFilter(
        workspacesList.filter((workspace) =>
          workspace.name.toLowerCase().includes(searchWorkspace.toLowerCase())
        )
      );
    } else {
      setWorkspacesListFilter(workspacesList);
    }
  }, [searchWorkspace]);

  const addFavorite = async (id) => {
    await setFavoriteWorkspace({
      id,
      favorite: true,
    })
      .then(() => {
        dispatch(
          setWorkspacesList(
            workspacesList?.map((workspace) => {
              if (workspace.id === id) {
                return { ...workspace, favorite: true };
              }
              return workspace;
            })
          )
        );
        dispatch(setWorkspaceFavorite(`${id}-add`));
      })
      .catch(() => {
        notification.open({
          message: t('SET_FAVORITE_WORKSPACE_ERROR'),
          type: 'error',
          duration: 3,
        });
      });
  };

  const removeFavorite = async (id) => {
    await setFavoriteWorkspace({
      id,
      favorite: false,
    })
      .then(() => {
        dispatch(
          setWorkspacesList(
            workspacesList?.map((workspace) => {
              if (workspace.id === id) {
                return { ...workspace, favorite: false };
              }
              return workspace;
            })
          )
        );
        dispatch(setWorkspaceFavorite(`${id}-remove`));
      })
      .catch(() => {
        notification.open({
          message: t('SET_FAVORITE_WORKSPACE_ERROR'),
          type: 'error',
          duration: 3,
        });
      });
  };

  const handleCreateWorkspace = async () => {
    const workspaceInfo = {
      name: workspaceName.trim(),
      companyId: user?.companyId, // A partner can only create workspace for his own company.
    };
    setCreateWorkspaceLoader(true);
    const response = await createWorkspaceAsync(workspaceInfo);
    if (response) {
      const workspace = {
        id: response.workspaceId,
        favorite: false,
        color: '',
        name: workspaceName.trim(),
        icon: '',
        iconId: null,
      };
      const userData = await getMe();
      dispatch(setUser(userData));
      dispatch(setCurrentWorkspace(response.workspaceId));
      dispatch(setCurrentRole(PartnerWorkspaceRole.OWNER));
      dispatch(setWorkspacesList([...workspacesList, workspace]));
      setShowModalAddWorkspace(false);
      setWorkspaceName('');
      setCreateWorkspaceLoader(false);
      history.push(CREATED_WORKSPACE);
    } else {
      setShowModalAddWorkspace(false);
      setCreateWorkspaceLoader(false);
      setWorkspaceName('');
      notification.open({
        message: t('ERROR_TO_CREATE_WORKSPACE'),
        type: 'error',
      });
    }
  };

  const createWorkspace = () => {
    setOpen(false);
    setShowModalAddWorkspace(true);
  };

  const redirectToConfig = () => {
    setOpen(false);
    history.push(CONFIGURATION);
  };

  useEffect(() => {
    setWorkspacesListFilter(workspacesList);
  }, [workspacesList]);

  return (
    <div
      className={`ml-2 d-flex align-self-end align-self-sm-center dropdown-workspace-max-height ${
        open && 'dropdown-workspace-container-index'
      }`}
    >
      {showModalAddWorkspace && (
        <ModalCustom
          datatestid="modal-create-workspace"
          datatestidbuttonok="button-ok"
          title={t('CREATE_WORKSPACE')}
          htmlSubtitle
          show={showModalAddWorkspace}
          setShow={setShowModalAddWorkspace}
          onOkText={t('CREATE')}
          onCancelText={t('CANCEL')}
          onCancel={() => setShowModalAddWorkspace(false)}
          onOk={handleCreateWorkspace}
          classModal="modal-create-workspace"
          showCross={false}
          isLoading={createWorkspaceLoader}
          input={
            <input
              ref={(ref) => ref && ref.focus()}
              className="input-name-workspace input outline-none px-3 py-2 font-montserrat input-search text-base mx-auto mt-3 mb-2"
              onChange={(e) => setWorkspaceName(e.target.value)}
              type="text"
              value={workspaceName}
              placeholder={t('CREATE_WORKSPACE_INPUT_NAME')}
            />
          }
        />
      )}
      <div
        ref={ref}
        className={`rounded font-roboto font-weight-bold non-selectable dropdown-workspace-select-container b-radius-8 position-relative ${className} ${
          !collapsed && 'width-button-open'
        }`}
      >
        <div
          onClick={() => setOpen((state) => !state)}
          aria-hidden
          className="cursor-pointer p-2 w-100 d-flex align-items-center"
        >
          {determineIconType(workspaceInfo)}
          {!collapsed && (
            <>
              <span
                data-testid="name-workspace-placeholder"
                className="dropdown-workspace-select-placeholder text-white font-roboto text-base overflow-hidden ml-2 mr-1"
              >
                {addTruncate(workspaceInfo.placeholder, 12) ?? ''}
              </span>
              <div className="dropdown-workspace-select-arrow mx-1 ml-auto">
                <ArrowDropdown />
              </div>
            </>
          )}
        </div>
        <div
          className={`dropdown-workspace-select-options-container bg-white overflow-auto m-0 position-absolute b-radius-8 ${
            open && 'dropdown-workspace-select-options-container-open pt-2'
          }`}
        >
          <FormInputSearch
            searchText={searchWorkspace}
            setSearchText={setSearchWorkspace}
            placeholder={t('WORKSPACE_INPUT_SEARCH')}
            className="w-90"
          />
          {!worskpacesListFilter.length ? (
            <div className="text-center my-2 p-3">
              <p className="font-montserrat m-0 font-weight-600 text-dark mb-2">
                {t('WORKSPACE_EMPTY_STATE_TITLE')}
              </p>
              <p className="font-montserrat m-0 text-dark font-weight-400">
                {t('WORKSPACE_EMPTY_STATE_SUBTITLE')}
              </p>
            </div>
          ) : (
            <ul className="my-2 ml-2 ul-container overflow-auto custom-scrollbar">
              {worskpacesListFilter
                .sort((a, b) => (a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1))
                .map((option) => (
                  <li
                    className="d-flex align-items-center p-2 cursor-pointer dropdown-workspace-select-option justify-content-between"
                    key={option.id}
                    role="option"
                    aria-selected
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{`${option.companyName} - ${option.name}`}</Tooltip>}
                    >
                      <button
                        className="d-flex align-items-center bg-transparent border-0 p-0 w-80"
                        type="button"
                        onClick={() => setWorkspace(option.id)}
                        onKeyPress={() => setWorkspace(option.id)}
                        data-testid={`button-option-${option.name}`}
                      >
                        {determineIconType(option)}

                        <span className="ml-2 font-roboto text-dark font-weight-600 w-75">
                          {addTruncate(option.name, 14)}
                        </span>
                      </button>
                    </OverlayTrigger>
                    <button
                      data-testid={`button-star-${option.id}`}
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        !option.favorite ? addFavorite(option?.id) : removeFavorite(option?.id)
                      }
                    >
                      {option.favorite ? (
                        <StarComplete testid={`star-complete-${option.id}`} />
                      ) : (
                        <div data-testid={`star-empty-${option.id}`} className="star-empty">
                          <StarEmpty />
                        </div>
                      )}
                    </button>
                  </li>
                ))}
            </ul>
          )}
          <div className="d-flex justify-content-between footer-dropdown-workspace p-2">
            <button
              data-testid="create-workspace-button"
              type="button"
              onClick={createWorkspace}
              className="border-0 bg-transparent font-montserrat font-weight-600 text-xs text-gray"
            >
              {t('CREATE_WORKSPACE')}
            </button>
            <button
              data-testid="config-workspace-button"
              type="button"
              className="border-0 bg-transparent font-montserrat font-weight-600 text-xs text-gray"
              onClick={redirectToConfig}
            >
              {t('CONFIG_WORKSPACE')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceDropdown;
