import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import buttonEditImage from '../assets/profile/button-edit-image.svg';
import { getMe } from '../API/services/authService';
import { setUser } from '../redux/actions/authActions';
import {
  getPartnersByWorkspaceId,
  uploadImagePartner,
  uploadImageProfile,
} from '../API/services/alkymersService';
import { saveCompany } from '../API/services/companyService';
import { t } from '../i18n/i18n';
import './CompanyProfile.scss';
import BackgroundTop from '../components/BackgroundTop/BackgroundTop';
import OtherPartnersProfile from '../components/OtherPartnersProfile/OtherPartnersProfile';
import profileImageNull from '../assets/profile/profile.png';
import NewStyleInput from '../components/NewStyleInput/NewStyleInput';
import profileImg from '../assets/header/profile.svg';
import NewStyleSelect from '../components/NewStyleSelect/NewStyleSelect';
import { getTextColor } from '../utils/colors/determineColor';
import { TrackEvent } from '../utils/filters/segmentUtils';
import { languagesList } from '../components/DropdownLanguage/DropdownLanguage';
import { getLocalStorageWorkspace, setLocalStorageWorkspace } from '../utils/workspaceUtils';

const hiringOptions = [
  {
    name: t('COMPANY_PROFILE_ONE_TO_TEN'),
    id: '1-10',
  },
  {
    name: t('COMPANY_PROFILE_TEN_TO_TWENTY'),
    id: '11-20',
  },
  {
    name: t('COMPANY_PROFILE_TWENTY_TO_FIFTY'),
    id: '21-50',
  },
  {
    name: t('COMPANY_PROFILE_FIFTY_TO_HUNDRED'),
    id: '51-100',
  },
  {
    name: t('COMPANY_PROFILE_HUNDRED_TO_THREE_HUNDRED'),
    id: '101-300',
  },
  {
    name: t('COMPANY_PROFILE_MORE_THAN_THREE_HUNDRED'),
    id: '+300',
  },
];

function CompanyProfileEdit() {
  const { user, currentWorkspace } = useSelector((state) => state?.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(user);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    primaryColor: '',
    companyPicture: '',
  });
  const [annualHirings, setAnnualHirings] = useState(user?.company?.annualHires ?? null);
  const [preferredLanguage, setPreferredLanguage] = useState(user?.preferredLanguage);
  const [color, setColor] = useState({
    color1: getLocalStorageWorkspace()?.companyPrimaryColor || '#58c1f5',
  });

  useEffect(async () => {
    if (
      !profile?.workspaces?.filter((workspace) => workspace.workspaceId === currentWorkspace)
        ?.partners
    ) {
      const partners = await getPartnersByWorkspaceId(currentWorkspace);
      let editWorkspace = profile?.workspaces?.filter(
        (workspace) => workspace.workspaceId === currentWorkspace
      );
      editWorkspace = { ...editWorkspace, partners };
      const workspaces = profile?.workspaces?.map((workspace) =>
        workspace.id !== editWorkspace?.id ? workspace : editWorkspace
      );
      setProfile({ ...user, workspaces });
      dispatch(
        setUser({
          ...user,
          workspaces,
        })
      );
    }
  }, [currentWorkspace]);

  const save = () => {
    const errors = {
      firstName: '',
      lastName: '',
      primaryColor: '',
      companyPicture: '',
    };
    const firstNameTrimed = profile?.firstName?.trim();
    const lastNameTrimed = profile?.lastName?.trim();
    if (firstNameTrimed?.length > 2) {
      if (firstNameTrimed?.length <= 50) {
        errors.firstName = [];
      } else {
        errors.firstName = t('PROFILE_NAME_IS_LONG');
      }
    } else {
      errors.firstName = t('PROFILE_NAME_IS_SHORT');
      if (!firstNameTrimed?.length) {
        errors.firstName = t('PROFILE_NAME_IS_REQUIRED');
      }
    }
    if (lastNameTrimed?.length > 2) {
      if (lastNameTrimed?.length <= 50) {
        errors.lastName = [];
      } else {
        errors.lastName = t('PROFILE_LASTNAME_IS_LONG');
      }
    } else {
      errors.lastName = t('PROFILE_LASTNAME_IS_SHORT');
      if (!lastNameTrimed?.length) {
        errors.lastName = t('PROFILE_LASTNAME_IS_REQUIRED');
      }
    }
    if (!color?.color1?.length) {
      errors.primaryColor = t('PROFILE_MAIN_COLOR_IS_REQUIRED');
    }
    if (!getLocalStorageWorkspace()?.companyLogo) {
      errors.companyPicture = t('PROFILE_LOGO_IS_REQUIRED');
    }
    setErrors(errors);
    if (
      !errors?.firstName?.length &&
      !errors?.lastName?.length &&
      !errors?.primaryColor?.length &&
      !errors?.companyPicture?.length
    ) {
      saveCompany({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        companyRequest: profile?.companyRequest,
        approved: profile?.approved,
        environment: profile?.environment,
        role: profile?.role,
        picture: profile?.picture,
        annualHires: annualHirings,
        technologies: profile?.technologies.map((item) => ({
          technologyId: item.technologyId ?? item.id,
          technologyType: 'HIRES',
        })),
        preferredLanguage,
        ...(color?.color1 ? { primaryColor: color?.color1 } : {}),
      }).then((res) => {
        notification.open({
          message: t('PROFILE_UPDATED_SUCCESSFULLY'),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
        setProfile({
          ...profile,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res?.email,
          companyRequest: res?.companyRequest,
          approved: res?.approved,
          environment: res?.environment,
          role: res?.role,
          picture: res?.picture,
          annualHires: res.annualHirings,
          technologies: profile.technologies,
          company: {
            ...profile.company,
            primaryColor: res.primaryColor,
          },
        });
        setLocalStorageWorkspace({
          ...getLocalStorageWorkspace(),
          companyPrimaryColor: res.primaryColor,
        });
        getMe()
          .then((data) => {
            dispatch(setUser(data));
          })
          .catch((error) => {
            TrackEvent('partner-get-me-error', { error });
            history.push('/error');
          });
      });
    }
  };

  const changeColor = (e) => {
    setColor({ ...color, [e.target.name]: e.target.value });
    setErrors({});
  };

  const uploadImage = async (e) => {
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0]?.type)) {
      await uploadImagePartner(fmData).then((res) => {
        setProfile({
          ...profile,
          company: {
            ...res?.company,
            partners: profile?.company?.partners,
          },
          workspaces: user?.workspaces?.map((workspace) =>
            workspace.workspaceId === currentWorkspace
              ? {
                  ...workspace,
                  companyLogo: res?.company?.picture,
                }
              : workspace
          ),
        });
        dispatch(
          setUser({
            ...user,
            workspaces: user?.workspaces?.map((workspace) =>
              workspace?.workspaceId === currentWorkspace
                ? {
                    ...workspace,
                    companyLogo: res?.company?.picture,
                  }
                : workspace
            ),
          })
        );
        setLocalStorageWorkspace({
          ...getLocalStorageWorkspace(),
          companyLogo: res?.company?.picture,
        });
        setErrors({});
      });
    } else {
      notification.open({
        message: t('IMAGE_FORMAT_INVALID'),
        type: 'error',
      });
    }
  };

  const uploadImagePrf = async (e) => {
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0]?.type)) {
      await uploadImageProfile(fmData).then((res) => {
        dispatch(setUser({ ...user, picture: res?.picture }));
        setProfile({
          ...profile,
          picture: res.picture,
        });
      });
    } else {
      notification.open({
        message: t('IMAGE_FORMAT_INVALID'),
        type: 'error',
      });
    }
  };

  return (
    <div data-testid="company-profile-container" className="w-100 mx-auto h-100 pb-5">
      <BackgroundTop
        title={t('COMPANY_MY_PROFILE')}
        className="position-relative"
        showBase
        titleClassname="text-xl"
      />
      <div className="position-relative d-flex flex-column flex-lg-row description-container w-100 mx-auto text-left">
        <div>
          <div className="user-heading rounded position-relative w-100 mb-3 align-self-start px-5 pb-4 pt-4 text-center d-flex flex-column align-items-center profile-menu-container">
            <span className="font-weight-bold font-montserrat text-base mr-auto">
              {t('PROFILE_PICTURE_OF_PROFILE')}
            </span>
            <div className="d-flex flex-column align-items-center justify-content-center user-heading-round rounded-circle px-4 position-relative">
              <div className="position-relative">
                <img
                  className="user-heading-img rounded-circle mt-4 p-1"
                  src={profile?.picture || profileImg}
                  alt={t('PROFILE_MENU_LOGO_USER')}
                />
                <div className="image-upload position-absolute right-0 bottom-0">
                  <label htmlFor="file-input" className="m-0 p-0">
                    <img
                      src={buttonEditImage}
                      className="button-edit-image-profile cursor-pointer"
                      alt={t('EDIT_ALT')}
                    />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={uploadImagePrf}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="user-heading rounded position-relative w-100 mb-3 align-self-start px-5 pb-4 pt-4 text-center d-flex flex-column align-items-center profile-menu-container">
            <div className="d-flex flex-row mr-auto">
              <span className="font-weight-bold font-montserrat text-base">
                {t('PICTURE_OF_THE_COMPANY')}
              </span>
              <span className="font-weight-bold font-montserrat text-base mr-auto text-danger ml-1">
                *
              </span>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center user-heading-round rounded-circle px-4 position-relative">
              <div className="position-relative">
                <img
                  className="user-heading-img rounded-circle mt-4 p-1"
                  src={
                    profile?.workspaces?.filter(
                      (workspace) => workspace.workspaceId === currentWorkspace
                    )[0]?.companyLogo || profileImageNull
                  }
                  alt={t('PROFILE_MENU_LOGO_USER')}
                />
                <div className="image-upload position-absolute right-0 bottom-0">
                  <label htmlFor="file-input-company" className="m-0 p-0">
                    <img
                      src={buttonEditImage}
                      className="button-edit-image-profile cursor-pointer"
                      alt={t('EDIT_ALT')}
                    />
                  </label>
                  <input
                    id="file-input-company"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={uploadImage}
                  />
                </div>
              </div>
            </div>
            {!!errors?.companyPicture?.length && (
              <span className="font-montserrat text-danger text-xs mt-1">
                {errors?.companyPicture}
              </span>
            )}
          </div>
          {profile?.workspaces[0]?.partners?.length && (
            <OtherPartnersProfile partners={profile?.workspaces[0]?.partners} id={profile?.id} />
          )}
        </div>
        <div className="ml-lg-3">
          <div className="bg-white py-4 pr-4 pl-4 w-100 rounded">
            <span className="font-weight-bold pt-3 font-montserrat text-base">
              {t('COMPANY_ABOUT_ME')}
            </span>
            <div className="mt-2 d-flex">
              <NewStyleInput
                className="mt-3"
                value={profile?.firstName}
                onChange={(e) => {
                  setProfile({ ...profile, firstName: e.target.value });
                  setErrors({});
                }}
                errors={errors?.firstName}
                required
                title={t('COMPANY_PROFILE_NAME')}
                name="firstName"
              />
              <NewStyleInput
                className="mt-3 mx-2"
                value={profile?.lastName}
                onChange={(e) => setProfile({ ...profile, lastName: e.target.value })}
                errors={errors?.lastName}
                required
                title={t('COMPANY_LAST_NAME_LABEL')}
                name="lastName"
              />
              <NewStyleInput
                className="mt-3"
                error={false}
                title={t('COMPANY_ROLE')}
                value={profile?.role}
                onChange={(e) => setProfile({ ...profile, role: e.target.value })}
                name="role"
              />
            </div>
            <div className="mt-3 d-flex">
              <NewStyleInput
                className=""
                value={profile?.email}
                disabled
                required
                title={t('EMAIL')}
                name="email"
              />
            </div>
            <div className="d-flex mt-1 pt-2 w-33" />
            <NewStyleSelect
              error={false}
              className="mt-3"
              title={t('COMPANY_PROFILE_PREFERRED_LANGUAGE')}
              value={preferredLanguage}
              options={languagesList?.map((x) => ({ id: x.value, name: x.label }))}
              onChange={(e) => {
                setPreferredLanguage(e.target.value);
              }}
              name="preferredLanguage"
            />
          </div>
          <div className="bg-white py-4 pr-4 pl-4 w-100 rounded mt-3">
            <span className="font-weight-bold pt-3 font-montserrat text-base">
              {t('COMPANY_PROFILE_ABOUT_COMPANY')}
            </span>
            <div className="mt-2 d-flex flex-row pt-2">
              <div className="container-inputs-profile">
                <NewStyleInput
                  error={false}
                  disabled
                  title={t('COMPANY_PROFILE_NAME')}
                  name="company"
                  value={getLocalStorageWorkspace()?.companyName}
                />
              </div>
              <NewStyleSelect
                error={false}
                className="container-inputs-profile ml-3"
                title={t('COMPANY_PROFILE_TEAM_SIZE')}
                value={annualHirings}
                options={hiringOptions}
                onChange={(e) => setAnnualHirings(e.target.value)}
                name="sizeTeam"
              />
            </div>
          </div>

          <div className="bg-white py-4 pr-4 pl-4 w-100 rounded mt-3">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <span className="font-weight-bold font-montserrat text-base">
                  {t('PERSONALIZATION')}
                </span>
                <span className="font-montserrat font-weight-bold text-danger text-base ml-1">
                  *
                </span>
              </div>
              <span className="font-montserrat text-sm font-weight-500 mt-1 mr-0 mr-lg-5 pr-0 pr-lg-5">
                {t('PERSONALIZATION_TEXT_PROFILE')}
              </span>
              <span className="font-montserrat text-sm mt-3">{t('PROFILE_MAIN_COLOR')}</span>
              <div className="d-flex align-items-center mt-2 px-2 py-1 input-type-color-profile">
                <input
                  type="color"
                  onChange={changeColor}
                  className="input-picker-color-custom cursor-pointer"
                  name="color1"
                  value={color?.color1}
                />
                <span className="font-montserrat font-weight-600 text-xs ml-2">
                  {color?.color1}
                </span>
              </div>
              {!!errors?.primaryColor?.length && (
                <span className="font-montserrat text-danger mt-1">{errors?.primaryColor}</span>
              )}
              <div className="mt-3">
                <span className="font-montserrat font-weight-600 text-base text-black">
                  {t('PERSONALIZATION_PREVIEW')}
                </span>
                <div style={{ border: `1px solid ${color?.color1}` }} className="mt-2">
                  <div
                    className="py-3 px-4"
                    style={{
                      backgroundColor: color?.color1,
                      color: getTextColor(color?.color1 || ''),
                    }}
                  >
                    <img
                      className="rounded-circle profile-personalization-img"
                      src={
                        profile?.workspaces?.filter(
                          (workspace) => workspace.workspaceId === currentWorkspace
                        )[0]?.companyLogo || profileImageNull
                      }
                      alt={t('PROFILE_MENU_LOGO_USER')}
                    />
                    <span className="font-montserrat font-weight-600 text-base ml-3">
                      {t('PERSONALIZATION_TITLE_OF_EXAMPLE')}
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-center p-4 py-5 mt-2">
                    <button
                      type="button"
                      className="px-4 py-2 font-montserrat border-0 font-weight-700 text-sm profile-personalization-button"
                      style={{
                        backgroundColor: color?.color1,
                        color: getTextColor(color?.color1 || ''),
                      }}
                    >
                      {t('PERSONALIZATION_BUTTON_OF_EXAMPLE')}
                    </button>
                    <span className="font-montserrat font-weight-600 text-sm text-black text-decoration-none mt-2 mt-lg-0 ml-0 ml-md-5 cursor-pointer">
                      {t('PERSONALIZATION_LINK_OF_EXAMPLE')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={save}
            type="submit"
            className="float-right mt-3 button-save-profile border-0 px-4 py-1"
          >
            <span className="font-montserrat text-xs font-weight-700 text-white">{t('SAVE')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileEdit;
