import { Alert, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { updateNotification } from '../../API/services/notificationService';
import { setUser } from '../../redux/actions/authActions';
import GenericToggle from '../GenericToggle/GenericToggle';
import InfoIcon from '../../assets/search/InfoIcon';
import { t } from '../../i18n/i18n';

const Notification = ({ user = { firstName: 'firstName', lastName: 'lastName' }, className }) => {
  const dispatch = useDispatch();
  const { currentWorkspace } = useSelector((state) => state.auth);
  const [partnerToWorkspace, setPartnerToWorkspace] = useState({});
  const [indexUser, setIndexUser] = useState(0);
  const [isLoading, setIsLoading] = useState({
    dailySummary: false,
    weeklySummary: false,
  });

  useEffect(() => {
    const currentIndex = user?.workspaces?.findIndex(
      (item) => item?.workspaceId === currentWorkspace
    );
    if (currentIndex > -1) {
      const workspace = user.workspaces[currentIndex];
      setIndexUser(currentIndex);
      setPartnerToWorkspace(workspace);
    }
  }, [user]);

  const onChangeInput = async (data, isLoadingData) => {
    try {
      setIsLoading({ [isLoadingData]: true });
      const response = await updateNotification(currentWorkspace, user?.id, data);
      const newWorkspaces = [...user?.workspaces];
      newWorkspaces[indexUser].notification = response;
      const userData = { ...user, workspaces: newWorkspaces };
      dispatch(setUser(userData));
      notification.open({
        message: t('NOTIFICATIONS_SETTING_UPDATED'),
        type: 'success',
      });
    } catch (error) {
      notification.open({
        message: t('NOTIFICATIONS_SETTING_NOT_UPDATED'),
        type: 'error',
      });
    }
    setIsLoading({ [isLoadingData]: false });
  };
  const warning = t('NOTIFICATIONS_WARNING_PAGE')
    .replace('{{workspace}}', `(${partnerToWorkspace?.workspace?.name})`)
    .replace('{{username}}', `(${user?.firstName} ${user?.lastName})`);

  return (
    <div className="d-flex flex-column ">
      <div className="text-center m-4">
        <Alert className="bg-yellow-warning">
          <div className="d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mx-2" />
            <span>{warning}</span>
          </div>
        </Alert>
      </div>
      <div>
        <div className="d-flex flex-column">
          <div className="d-flex m-2 align-items-center">
            <GenericToggle
              defaultActive={partnerToWorkspace?.notification?.dailySummary}
              actionFunction={() => {
                !isLoading?.dailySummary &&
                  onChangeInput(
                    {
                      dailySummary: !partnerToWorkspace?.notification?.dailySummary,
                    },
                    'dailySummary'
                  );
              }}
            />

            <div className="mx-2">
              <OverlayTrigger
                trigger={['click', 'hover']}
                placement="right"
                overlay={
                  <Popover className="p-2 " style={{ backgroundColor: 'grey-light' }}>
                    <span>
                      <strong>{t('NOTIFICATIONS_DAILY_INFO_PAGE')}</strong>
                    </span>
                  </Popover>
                }
              >
                <Button className="without-button">
                  <span className="font-montserrat font-weight-600 text-light-black text-base mx-2">
                    {t('NOTIFICATIONS_OPTION_DAILY_PAGE')}
                  </span>
                  <InfoIcon />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="d-flex m-2 align-items-center">
            <GenericToggle
              data-testid="toggle"
              defaultActive={partnerToWorkspace?.notification?.weeklySummary}
              actionFunction={() => {
                !isLoading?.weeklySummary &&
                  onChangeInput(
                    {
                      weeklySummary: !partnerToWorkspace?.notification?.weeklySummary,
                    },
                    'weeklySummary'
                  );
              }}
            />

            <div className="mx-2">
              <OverlayTrigger
                trigger={['click', 'hover']}
                placement="right"
                overlay={
                  <Popover className="p-2 " style={{ backgroundColor: 'grey-light' }}>
                    <span>
                      <strong>{t('NOTIFICATIONS_WEEKLY_INFO_PAGE')}</strong>
                    </span>
                  </Popover>
                }
              >
                <Button className="without-button">
                  <span className="font-montserrat font-weight-600 text-light-black text-base mx-2">
                    {t('NOTIFICATIONS_OPTION_WEEKLY_PAGE')}
                  </span>
                  <InfoIcon />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
