import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { getMe } from '../../API/services/authService';
import { auth0Client } from '../../auth0/auth0';
import { ErrorType } from '../../constants/enums';
import { HOME, REGISTER } from '../../constants/routes';
import useWindowName from '../../hooks/useWindowName';
import LoggedLayout from '../../layout/LoggedLayout';
import LoggedOutLayout from '../../layout/LoggedOutLayout';
import NotApprovedLayout from '../../layout/NotApprovedLayout/NotApprovedLayout';
import NotApproved from '../../pages/NotApproved';
import {
  checkToken,
  setCurrentRole,
  setCurrentWorkspace,
  setUser,
} from '../../redux/actions/authActions';
import { Identify, TrackEvent } from '../../utils/filters/segmentUtils';
import { Window } from '../../utils/window';
import Spinner from '../spinner/Spinner';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';

function CheckToken({ children }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user) {
      dispatch(checkToken());
    }
  }, []);

  return <>{auth.isChecking ? <Spinner show /> : children}</>;
}

function PrivateRoute({ children, ...rest }) {
  const isLoading = useSelector((state) => state.auth.isLoadingMe);
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const [loading, setLoading] = useState(false);
  const { user, currentWorkspace } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { windowName } = useWindowName();

  const logout = () => {
    if (Window()) {
      localStorage.clear();
      auth0Client().logout({ returnTo: window.location.origin });
    }
  };
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      setLoading(true);
      getMe()
        .then((user) => {
          if (!user) logout();
          dispatch(setUser(user));
          if (!currentWorkspace) {
            dispatch(setCurrentWorkspace(user.workspaces[0]?.workspaceId));
            dispatch(setCurrentRole(user.workspaces[0]?.role));
          } else {
            dispatch(setCurrentRole(getLocalStorageWorkspace()?.role));
          }
          Identify(user?.id, user);
        })
        .catch((error) => {
          TrackEvent('partner-get-me-error', { error });
          history.push(`/error?type=${ErrorType.INVALID_ROLES}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (!isAuthenticated && isLoading) {
      const urlParams = new URLSearchParams(window.location.search);
      const workspaceId = urlParams.get('workspace');
      if (workspaceId) {
        localStorage.setItem('workspaceIdRedirect', workspaceId);
      }
    }
  }, [isAuthenticated, isLoading]);

  return (
    <>
      <Helmet>
        <title>{windowName}</title>
      </Helmet>
      <Route
        {...rest}
        render={() => {
          switch (true) {
            case (isLoading || loading) && user === null:
              return <Spinner show />;
            case isAuthenticated && Boolean(user?.company?.id):
              return <LoggedLayout>{children}</LoggedLayout>;
            case isAuthenticated &&
              user !== null &&
              !user?.company?.id &&
              location.pathname === HOME:
              return (
                <NotApprovedLayout>{user ? <NotApproved /> : <Spinner show />}</NotApprovedLayout>
              );
            case !isAuthenticated && !isLoading:
              return <Redirect to={REGISTER} />;
            default:
              return null;
          }
        }}
      />
    </>
  );
}

function LoggedOutRoute({ children, ...rest }) {
  const isLoading = useSelector((state) => state.auth.isLoadingMe);
  const { authenticated, isVerify } = useSelector((state) => state.auth);

  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return (
    <Route
      {...rest}
      render={() =>
        isLoading ? (
          <Spinner show />
        ) : authenticated && isVerify ? (
          <Redirect to={HOME} />
        ) : (
          <LoggedOutLayout>{children}</LoggedOutLayout>
        )
      }
    />
  );
}

function PublicRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return (
    <Route
      {...rest}
      render={() => (auth.token && auth.user ? <LoggedLayout>{children}</LoggedLayout> : children)}
    />
  );
}
function FreeRoute({ children, ...rest }) {
  return <Route {...rest} render={() => children} />;
}

export { CheckToken, FreeRoute, LoggedOutRoute, PrivateRoute, PublicRoute };
